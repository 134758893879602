<template>
  <div class="container">
    <StMobileMenu :is-active="isOpenedMenu">
      <TheMSidebar />
    </StMobileMenu>
    <TheMHeader v-bind="props" />
    <main class="main">
      <div class="content">
        <slot />
      </div>
    </main>
    <TheMFooter
      v-if="!withoutFooter"
      :without-footer-menu="withoutFooterMenu"
    />
    <MCoupon v-if="isShownCoupon" />
    <TapBar v-if="!withoutTapbar" />
  </div>
</template>

<script setup lang="ts">
import MCoupon from '@st/coupon/components/Coupon/MCoupon.vue'
import TheMHeader from '../TheHeader/TheMHeader.vue'
import TheMFooter from '../TheFooter/TheMFooter.vue'
import TapBar from '../TapBar/TapBar.vue'
import TheMSidebar from '../TheSidebar/TheMSidebar.vue'

const props = defineProps<{
  withMenuButton?: boolean
  withSearchButton?: boolean
  withBackButton?: boolean
  withoutFooter?: boolean
  withoutTapbar?: boolean
  withoutFooterMenu?: boolean
}>()

const { isOpenedMenu } = useMobileLayout()

const route = useRoute()
const isShownCoupon = computed(() =>
  Boolean(route.path.match('sport') || route.path.match('cybersport')),
)
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.main {
  flex-grow: 1;
  min-height: 0;
}
</style>
