<template>
  <MCouponBar v-if="state === 'collapsed'" @click="state = 'expanded'" />
  <StDrawer
    :model-value="state === 'quickBet'"
    :full-screen="false"
    :with-overlay="false"
    with-shadow
    @update:model-value="state = 'collapsed'"
  >
    <QuickBetForm @close="state = 'collapsed'" />
  </StDrawer>
  <StDrawer
    :model-value="state === 'expanded'"
    :full-screen="false"
    @update:model-value="state = 'collapsed'"
  >
    <BetMakingForm mobile @close="state = 'collapsed'" />
  </StDrawer>
  <div v-if="isLoading" class="coupon-overlay" />
</template>

<script setup lang="ts">
import { useCouponStore } from '../../stores/useCouponStore'

const { outcomeCount, isLoading } = storeToRefs(useCouponStore())

function getDefaultState() {
  return outcomeCount.value > 0 ? 'collapsed' : 'hidden'
}

const state = ref<'hidden' | 'collapsed' | 'expanded' | 'quickBet'>(
  getDefaultState(),
)

watch(outcomeCount, (newValue, oldValue) => {
  if (oldValue === 0 && newValue === 1) {
    state.value = 'quickBet'
  } else if (oldValue > 0 && newValue === 0) {
    state.value = 'hidden'
  } else if (oldValue === 1 && newValue >= 1) {
    state.value = 'collapsed'
  }
})
</script>

<style scoped>
.coupon-overlay {
  position: fixed;
  z-index: 111;
  inset: 0;
}
</style>
