<template>
  <div data-t="cybersport-menu">
    <StMobileMenuItem
      :title="t('menu.all')"
      icon="list"
      link="/mobile/cybersport"
      data-t="menu-item-all-sport"
    />
    <StMobileMenuItem
      :title="t('menu.live')"
      icon="play-solid"
      link="/mobile/cybersport/live"
      data-t="menu-item-live"
    />
    <StMobileMenuItem
      :title="t('menu.upcoming')"
      icon="timer"
      link="/mobile/cybersport/upcoming"
      data-t="menu-item-upcoming"
    />
    <template v-if="cybersports.length > 0">
      <div class="section-title">
        {{ t('menuMobile.cybersports') }}
      </div>
      <StMobileMenuItem
        v-for="item in cybersports"
        :key="item.entityId"
        :title="item.name"
        :icon="item.icon as any"
        :counter="item.liveSportEventsNumber"
        :data-t="`menu-item-${item.entityId}`"
        @click="emit('selectSport', item)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'

const { menu } = useCybersportMenu()
const { t } = useI18n()

const cybersports = computed(() => menu.value?.[0]?.children ?? [])

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'selectSport', value: ExtendedMenuItem): void
}>()
</script>

<style scoped>
.section-title {
  padding: var(--spacing-400) var(--spacing-200) var(--spacing-075)
    var(--spacing-200);
  font: var(--mobile-text-medium);
  color: var(--text-tertiary);
}
</style>
