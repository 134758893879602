<template>
  <div class="summary">
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.payoff') }}
      </span>
      <span class="summary-amount" data-t="summary-amount-xedq">
        {{ format(payoff) }}
        <StIcon :size="16" :name="currencyIcon" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import type { ExtendedCouponOutcome } from '../../stores/useCouponStore'

const { t } = useI18n()
const { format } = useCryptoFormatter()

const props = defineProps<{
  outcome: ExtendedCouponOutcome
  amount: string
  currencyIcon: string
}>()

const payoff = computed(() =>
  new Decimal(props.amount || 0)
    .times(props.outcome.outcome.odds || 1)
    .toString(),
)
</script>

<style scoped>
.summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  padding: var(--spacing-125) 0;
}

.summary-row {
  display: flex;
  justify-content: space-between;
}

.summary-label {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.summary-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  font: var(--desktop-text-sm-semibold);
}
</style>
