<template>
  <div class="sport-menu" data-t="sport-menu">
    <StMobileMenuItem
      :title="t('menu.all')"
      icon="list"
      link="/mobile/sport/all"
      data-t="menu-item-all-sport"
    />
    <StMobileMenuItem
      :title="t('menu.live')"
      icon="play-solid"
      link="/mobile/sport/live"
      data-t="menu-item-live"
    />
    <StMobileMenuItem
      :title="t('menu.upcoming')"
      icon="timer"
      link="/mobile/sport/upcoming"
      data-t="menu-item-upcoming"
    />
    <div class="section-title">
      {{ t('menuMobile.sports') }}
    </div>
    <StMobileMenuItem
      v-for="sport in menu"
      :key="sport.entityId"
      :title="sport.name"
      :icon="sport.icon as any"
      :counter="sport.liveSportEventsNumber"
      :data-t="`menu-item-${sport.entityId}`"
      @click="emit('selectSport', sport)"
    />
  </div>
</template>

<script setup lang="ts">
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'

const { menu } = useSportMenu()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'selectSport', value: ExtendedMenuItem): void
}>()
</script>

<style scoped>
.section-title {
  padding: var(--spacing-400) var(--spacing-200) var(--spacing-075)
    var(--spacing-200);
  font: var(--mobile-text-medium);
  color: var(--text-tertiary);
}

.sport-menu {
  padding-bottom: var(--spacing-600);
}
</style>
