<template>
  <Teleport to="body">
    <Transition name="menu">
      <div v-if="props.isActive" class="menu">
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
const props = defineProps<{
  isActive: boolean | undefined
}>()

const isMounted = ref(false)
onMounted(() => {
  isMounted.value = true
})

watchEffect(() => {
  if (!isMounted.value) return

  if (props.isActive) {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
})
</script>

<style scoped>
.menu {
  position: fixed;
  z-index: 101;
  inset: 0;
}

.menu-enter-active,
.menu-leave-active {
  transition: transform 0.4s;
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(-100%);
}
</style>
