<template>
  <div class="nested-sport-menu" data-t="nested-sport-menu">
    <div class="header">
      <button class="header-button" @click="closeMenu">
        <StIcon name="cross-large" size="24" />
      </button>
      <NuxtI18nLink to="/mobile" class="logo" data-t="logo">
        <img src="./images/m-logo.svg" alt="logotype" />
      </NuxtI18nLink>
      <button class="header-button" @click="openSearch">
        <StIcon name="search" size="24" />
      </button>
    </div>
    <div class="content-wrapper">
      <div class="breadcrumbs-wrapper" data-t="breadcrumbs">
        <StButton
          size="m"
          type="gray"
          :label="backButtonText"
          data-t="breadcrumb-back"
          @click="emit('back')"
        />
        <StButton
          v-for="item in selectedItems"
          :key="item.entityId"
          size="m"
          type="gray"
          :label="item.name"
          :data-t="`breadcrumb-${item.entityId}`"
          @click="goBackTo(item)"
        />
      </div>
      <div class="content">
        <StMobileMenuItem
          :title="t('menuMobile.allEvents')"
          :link="lastSelectedItem.mobileLink"
          :icon="allEventsIcon"
          :icon-size="iconSize"
          :counter="lastSelectedItem.liveSportEventsNumber"
          data-t="menu-item-all-events"
        />
        <StMobileMenuItem
          v-for="child in lastSelectedItem.children"
          :key="child.entityId"
          :title="child.name"
          :icon="child.icon as any"
          :icon-size="iconSize"
          :counter="child.liveSportEventsNumber"
          :link="child.children?.length ? undefined : child.mobileLink"
          :data-t="`menu-item-${child.entityId}`"
          @click="selectItem(child)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    sport?: ExtendedMenuItem
    tab?: 'sport' | 'cybersport'
  }>(),
  {
    sport: undefined,
    tab: 'sport',
  },
)

const backButtonText = computed(() =>
  props.tab === 'sport' ? t('menuMobile.sport') : t('menuMobile.cybersport'),
)

const emit = defineEmits<{
  (e: 'back'): void
}>()

const { closeMenu } = useMobileLayout()

const selectedItems = ref<ExtendedMenuItem[]>([])

watchEffect(() => {
  selectedItems.value = props.sport ? [props.sport] : []
})

function selectItem(item: ExtendedMenuItem) {
  if (!item.children?.length) return

  selectedItems.value.push(item)
}

const lastSelectedItem = computed(
  () => selectedItems.value.at(-1) as ExtendedMenuItem,
)

const iconSize = computed(() => (lastSelectedItem.value.level === 0 ? 20 : 24))
const allEventsIcon = computed(() =>
  lastSelectedItem.value.level === 1 ? undefined : 'list',
)

function goBackTo(item: ExtendedMenuItem) {
  const index = selectedItems.value.findIndex(
    ({ entityId }) => entityId === item.entityId,
  )

  if (index === selectedItems.value.length - 1) return

  selectedItems.value = selectedItems.value.slice(0, index - 1)
}

const router = useRouter()
function openSearch() {
  closeMenu()
  router.replace({ query: { modal: 'search' } })
}
</script>

<style scoped>
.nested-sport-menu {
  position: fixed;
  z-index: 1;
  inset: 0;

  display: flex;
  flex-direction: column;

  opacity: 1;
  background-color: var(--background-base);
}

.header {
  display: flex;
  flex: 0 0 auto;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-050);
  padding-right: var(--spacing-100);
  padding-bottom: var(--spacing-150);
}

.header-button {
  all: unset;
  padding: var(--spacing-100);
  line-height: 0;
}

.content-wrapper {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;

  min-height: 0;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.breadcrumbs-wrapper {
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  gap: var(--spacing-100);

  padding: var(--spacing-100);

  border-bottom: var(--border-width-medium) solid var(--border-secondary);
}

.content {
  overflow: auto;
  flex: 1 1 0;
  min-height: 0;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
